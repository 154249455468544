<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
// import DatePicker from "vue2-datepicker";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "เพิ่มใบส่งมอบรถ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader  }, //Multiselect, DatePicker
  data() {
    return {
      // tableData: tableData,

      title: "เพิ่มใบส่งมอบรถ",
      items: [
        {
          text: "ฝ่ายขาย",
        },
        {
          text: "ใบส่งมอบรถ",
          href: "/vehicle-sale"
        },
        {
          text: "เพิ่มใบส่งมอบรถ",
          active: true,
        },
      ],
      brand: "",
      // branchId:'',
      saleDate: "",

      searchNameCusbooking:"",
      searchbookingCode:"",
      fieldsbooking:[
        {
          key: "bookingDate",
          label: "วันที่",
        },
        {
          key: "bookingCode",
          sortable: true,
          label: "รหัสใบจองรถ",
        },
        {
          key: "productNameEn",
          sortable: true,
          label: "รุ่นรถ",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          label: "นามสกุล",
        },
        {
          key: "submittedBy",
          sortable: true,
          label: "ยืนยันโดย",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
        },
        
      ],
      filter: {
        pickingCode: "",
        customerFullNameTh: "",
        licensePlate: "",
        pickingDate: "",
      },

      branchIdSearch: "",
      overlayFlag: false,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,

      userBranchId: "",
      branchId: "",
      //   payMethCode: "",
      interiorCode: "",
      manageBranch: "",
      shortNameEn: "",
      shortNameTh: "",
      nameTh: "",

      nameEn: "",
      payMethod: "",
      submitform: false,
      errormessage: "กรุณาตรวจสอบข้อมูลอีกครั้ง",

      userBranch: "",
      rowsBooking:[],
      rowMasterBrands: [],
      filterOn: [],
      rows: [],
      sortBy: "age",
      disabledWsc: '',
      sortDesc: false,
      branch: "",
      rowProducts: [],
      rowsCustomers: [],
      rowsFin: [],
      rowsAct: [],
      rowsCampiagn: [],
      rowsExColor: [],
      rowsInColor: [],
      statusQn:'',
      rowsArr: [],
      rowsVm:[],
      rowSaleCondition: [
        {
          scId: "C",
          scName: "เงินสด",
        },
        {
          scId: "I",
          scName: "เช่าซื้อ / ผ่อน",
        },
        {
          scId: "CD",
          scName: "สด / เครดิต",
        },
        {
          scId: "OI",
          scName: "เช่าซื้อสถาบันอื่น ๆ",
        },
      ],
      postType: [
        {
          ptId: "R",
          nameTh: "รับเอง",
        },
        {
          ptId: "S",
          nameTh: "จัดส่ง",
        },
      ],
      taxTypeOptions: [
        {
          ttId: "T",
          nameTh: "มีภาษี",
        },
        {
          ttId: "NT",
          nameTh: "ไม่มีภาษี",
        },
      ],

      activity: "",

      vehicleTotalPrice: 0,
      vehicleDcPer: 0,
      vehicleDcPrice: 0,
      vehicleNetPrice: 0,
      partTotalPrice: 0,
      partDcPer: 0,
      partDcPrice: 0,
      partNetPrice: 0,
      extraTotalPrice: 0,
      extraDcPer: 0,
      extraDcPrice: 0,
      extraNetPrice: 0,
      totalPrice: 0,
      dcPer: 0,
      dcPrice: 0,
      netPrice: 0,
      vatPer: 0,
      vatPrice: 0,
      grandTotalPrice: 0,
      saleCondition: "",
      downPayment: 0,
      totalFinancing: 0,
      fiId: "",
      interest: 0,
      numPeriods: 0,
      installment: 0,
      note: "",
      prodId: "",
      actId: "",
      exteriorColorId: "",
      interiorColorId: "",
      campaignId: "",
      recommenderId: "",
      addrId: "",
      vehicleId:"",
      referralFee: 0,
      licensePlateDelivery: "",
      productDelivery: "",
      taxType: "",
      installmentDate: "",

    };
  },
  validations: {
    saleDate: {
      required,
    },
    branch: {
      required,
    },
    nameTh: {
      required,
    },
    nameEn: {},

    shortNameTh: {},
    shortNameEn: {},
    prodId: {
      required,
    },
    activity: {
      required,
    },
    exteriorColorId: {
      required,
    },
    interiorColorId: {
      required,
    },
    campaignId: {},
    vehicleTotalPrice: {
      required,
    },
    vehicleDcPer: {
      required,
    },
    vehicleDcPrice: {
      required,
    },
    vehicleNetPrice: {
      required,
    },
    downPayment: {
      required,
    },
    totalFinancing: {
      required,
    },
    saleCondition: {
      required,
    },
    numPeriods: {
      required,
    },
    installment: {
      required,
    },
    interest: {
      required,
    },
    fiId: {
    },
    note: {},
    recommenderId: {},
    addrId: {},
    referralFee: {
      required,
    },
    licensePlateDelivery: {
      required,
    },
    productDelivery: {
      required,
    },
    taxType: {
      required,
    },
    installmentDate: {
    },
    vehicleId:{
      required
    },
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {},
  created() {
    // this.getMasterBrand();
    this.getLocalData();
    this.getProduct();
    this.getCustomer();
    this.getFin();
    this.getActivity();
    this.getCampaign();
    this.getExColor();
    this.getInColor();
    this.getVehicle();
    // this.getCustomerArr();
  },
  methods: {
    changeSc(scId){
      if(scId=='C' ){
        // console.log(scId);
        this.disabledWsc = 'dis'
        this.fiId = []
        this.downPayment = 0.00
        this.qnVatPer = 0.00
        this.interest = 0.00
        this.numPeriods=0,
        this.installment =0.00
        this.totalFinancing = 0.00


      }else{
        this.disabledWsc = ''
      }

    },
    getdataBooking(){
      this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/vehicle-sale/branch-credit-inquire", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsBooking = response.data.data;
          console.log("booking=>", this.rowsBooking);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });

    },
    onRowSelectedbooking(rowsBooking){
      console.log(rowsBooking);
      Swal.fire({
        title: `ต้องการสร้างใบจองจากใบจองรถ \n ${rowsBooking[0].bookingCode} \n ใช่หรอไม่?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ต้องการเปิดใบจอง",
        cancelButtonText: "ยกเลิก"
      }).then((result) => {
        if (result.value) {
          this.submitSaleWithBooking(rowsBooking[0]);
        }
      });
    },
    submitSaleWithBooking(rowsBooking){
      console.log(rowsBooking);
      this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/vehicle-sale/store-booking", {
          bookingId: rowsBooking.bookingId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "vehicle-sale/edit",
            params: {
              saleId: response.data.saleId,
            },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
    popupmodalSelectbooking(){
      this.$refs.modalSelectbooking.show();
      this.getdataBooking();
      console.log('booking');
    },
    setProduct(vehicle) {
      this.vehicleTotalPrice = parseInt(vehicle.price);
      this.calProd();
      this.getVehicle()
    },
    // calProd() {
    //   var dcPrice;
    //   var netPrice;
    //   var totalFinancing;
    //   var installments;

    //   if (!isNaN()) {
    //     console.log("No");
    //   } else {
    //     dcPrice =
    //       this.vehicleTotalPrice * (parseFloat(this.vehicleDcPer) / 100);
    //     netPrice = this.vehicleTotalPrice - dcPrice;
    //     totalFinancing = netPrice - parseFloat(this.downPayment);
    //     installments =
    //       (totalFinancing / this.numPeriods) * (1 + this.interest / 100);

    //     this.vehicleDcPrice = dcPrice;
    //     this.vehicleNetPrice = netPrice;
    //     this.totalFinancing = totalFinancing;
    //     this.installment = installments.toFixed(2);

    //     // console.log("isNaN");
    //     // console.log("this.vehicleTotalPrice=>", this.vehicleTotalPrice);
    //     // console.log("dcPrice", dcPrice);
    //     // console.log("this.vehicleDcPer=>", parseFloat(this.vehicleDcPer));
    //     // console.log("this.downPayment=>", parseFloat(this.downPayment));
    //     // console.log("netPrice=>", netPrice);
    //     // console.log("totalFinancing=>", totalFinancing);
    //     // console.log("this.numPeriods=>", this.numPeriods);
    //     // console.log(
    //     //   "this.interest=> ",
    //     //   this.interest,
    //     //   " ->",
    //     //   1 + this.interest / 100
    //     // );
    //     // console.log("installments=>", installments);
    //   }
    // },
    calProd() {
      var dcPrice;
      var netPrice;
      var totalFinancing;
      var installments;
      var dPerYear;

      if (!isNaN()) {
        //console.log("No");
      } else {
        dcPrice =
          this.vehicleTotalPrice * (parseFloat(this.vehicleDcPer) / 100);
        netPrice = this.vehicleTotalPrice - dcPrice;
        totalFinancing = netPrice - parseFloat(this.downPayment);
        dPerYear = ((totalFinancing * (1 + this.interest / 100)) - totalFinancing) * (this.numPeriods/12);
        // dPerYear = ((totalFinancing * (1 + this.interest / 100)) /  (this.numPeriods/12));
        
        installments =
          // (totalFinancing / this.numPeriods * (1 + this.interest / 100));
          parseFloat(dPerYear + totalFinancing) / this.numPeriods
        
        
        // console.log(installments);
        this.vehicleDcPrice = dcPrice;
        this.vehicleNetPrice = netPrice;
        this.totalFinancing = totalFinancing;
        this.installment = installments.toFixed(2);
      }
    },
    customLabelVehicle({ stockNameTh, vin , productNameTh , engineNumber, chassisNumber, exteriorColorNameTh} ) {
      return `[${stockNameTh}]  
      
      ${productNameTh} 
      - สีภายนอก : ${exteriorColorNameTh == null ? '-' : exteriorColorNameTh}, 
      เลขตัวถัง : ${vin == null ? '-' : vin} ,
        เลขเครื่อง :${engineNumber == null ? '-' : engineNumber},
        เลขคัสซี : ${chassisNumber == null ? '-' : chassisNumber}` ;
    },

    customLabelProduct({ prodCode, nameEn }) {
      return `[${prodCode}]  ${nameEn}`;
    },
    customLabelCustomer({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh}`;
    },
    customLabelCustomerArr({
      buildingNumber,
      districtNameTh,
      amphurNameEn,
      provinceNameTh,
      postCode,
    }) {
      return `บ้านเลขที่ ${buildingNumber == null ? "-" : buildingNumber} 
      ตำบล ${districtNameTh == null ? "-" : districtNameTh} , 
      อำเภอ ${amphurNameEn == null ? "-" : amphurNameEn},  
       ${provinceNameTh == null ? "-" : provinceNameTh} , 
       ${postCode == null ? "-" : postCode}`;
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      this.branchId = user.branchId;
      // console.log(this.localDataBranch);
    },
    /**
     * Search the table data with search input
     */

    tooltipForm() {
      // console.log('No !', this.$v)
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        // console.log('Yes !', this.$v)
        this.submitBooking();
      }
    },

    getInColor() {
      this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/vehicle-sale/branch-interior-color", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsInColor = response.data.data;
          // console.log("InC=>", this.rowsInColor);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getExColor() {
      this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/vehicle-sale/branch-exterior-color", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsExColor = response.data.data;
          // console.log("ExC=>", this.rowsExColor);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getCampaign() {
      this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/vehicle-sale/branch-campaign", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsCampiagn = response.data.data;
          // console.log("CAM=>", this.rowsCampiagn);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getActivity() {
      this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/vehicle-sale/branch-activity", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsAct = response.data.data;
          // console.log("ACT=>", this.rowsAct);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getVehicle() {
      this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/vehicle-sale/branch-stock-vehicle", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
            prodId: this.prodId.prodId
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsVm = response.data.data;
          // console.log("Vehicle=>", this.rowsVm);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getFin() {
      this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/vehicle-sale/branch-financial-institution", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsFin = response.data.data;
          // console.log("FIN=>", this.rowsFin);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getCustomerArr() {
      // this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/vehicle-sale/branch-customer-address", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
            cusId: this.nameTh.cusId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsArr = response.data.data;
          console.log("ARR=>", this.rowsArr);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getCustomer() {
      // this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/vehicle-sale/branch-customer", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
            nameTh: this.nameTh
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsCustomers = response.data.data;
          // console.log("CUS=>", this.rowsCustomers);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getRecommenderId() {
      // this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/vehicle-sale/branch-customer", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
            nameTh: this.recommenderId.nameTh,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsCustomers = response.data.data;
          // console.log("CUS=>", this.rowsCustomers);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getProduct() {
      this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/vehicle-sale/branch-product		", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowProducts = response.data.data;
          // console.log("PC=>", this.rowProducts);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    submitBooking: function() {
      // console.log("in func");
      this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/vehicle-sale/store", {
          branchId: this.branch.branchId,
          cusId: this.nameTh.cusId,
          saleDate: this.saleDate,
          vehicleTotalPrice: this.vehicleTotalPrice,
          vehicleDcPer: this.vehicleDcPer,
          vehicleDcPrice: this.vehicleDcPrice,
          vehicleNetPrice: this.vehicleNetPrice,
          partTotalPrice: 0,
          partDcPer: 0,
          partDcPrice: 0,
          partNetPrice: 0,
          extraTotalPrice: 0,
          extraDcPer: 0,
          extraDcPrice: 0,
          extraNetPrice: 0,
          totalPrice: this.vehicleNetPrice,
          dcPer: 0,
          dcPrice: 0,
          netPrice: this.vehicleNetPrice,
          vatPer: 0,
          vatPrice: 0,
          // actId: this.actId.actId,
          grandTotalPrice: this.vehicleNetPrice,
          saleCondition: this.saleCondition.scId,
          downPayment: this.downPayment,
          totalFinancing: this.totalFinancing,
          fiId: this.fiId.fiId,
          interest: this.interest,
          numPeriods: this.numPeriods,
          installment: this.installment,
          note: this.note,
          prodId: this.prodId.prodId,
          actId: this.activity.actId,
          exteriorColorId: this.exteriorColorId.exteriorColorId,
          interiorColorId: this.interiorColorId.interiorColorId,
          campaignId: this.campaignId.campaignId,

          recommenderId: this.recommenderId.cusId,
          addrId: this.addrId.addrId,
          vehicleId: this.vehicleId.vehicleId,
          referralFee: this.referralFee,
          licensePlateDelivery: this.licensePlateDelivery.ptId,
          productDelivery: this.productDelivery.ptId,
          taxType: this.taxType.ttId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "vehicle-sale/edit",
            params: {
              saleId: response.data.saleId,
            },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-overlay
      :show="overlayFlag"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-9">
                      <!-- ** กรณีเลือกจากใบจองรถ ไม่ต้องกรอกข้อมูลด้านล่าง ** -->
                       <code>* </code>เลขที่ใบจองรถ : <br>
                      <input @click="popupmodalSelectbooking()" class="form-control" type="text">

                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <br>
                      <a @click="popupmodalSelectbooking()" class="btn btn-primary"><i class="uil uil-search"></i> เลือกจากใบจองรถ</a>
                    </div>
                    </div>
                  </div>
                  <hr>
                  <!-- <div class="row">
                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <code> * </code> สาขา<br />
                        <multiselect
                          v-model="branch"
                          :options="localData"
                          label="nameTh"
                          :class="{
                            'is-invalid': submitform && $v.branch.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.branch.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.branch.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>ชื่อลูกค้า:
                        <multiselect
                          v-model="nameTh"
                          label="nameTh"
                          type="search"
                          :options="rowsCustomers"
                          :show-labels="false"
                          open-direction="auto"
                          @search-change="getCustomer"
                          @remove="getCustomer"
                          @input="getCustomerArr"
                          :custom-label="customLabelCustomer"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.nameTh.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> วันที่ :
                        <date-picker
                          v-model="saleDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          :class="{
                            'is-invalid': submitform && $v.saleDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.saleDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.saleDate.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> กิจกรรมการขาย :
                        <multiselect
                          v-model="activity"
                          label="nameTh"
                          type="search"
                          :options="rowsAct"
                          open-direction="bottom"
                          placeholder="กิจกรรมการขาย"
                          :class="{
                            'is-invalid': submitform && $v.activity.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.activity.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.activity.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> รหัสสินค้า :
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          :value="prodId.prodCode"
                        />
                        <div
                          v-if="submitform && $v.prodId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.prodId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <code> * </code> ชื่อสินค้า :
                        <multiselect
                          @input="setProduct(prodId)"
                          v-model="prodId"
                          label="nameEn"
                          type="search"
                          :options="rowProducts"
                          open-direction="bottom"
                          placeholder="ชื่อสินค้า"
                          :custom-label="customLabelProduct"
                          :class="{
                            'is-invalid': submitform && $v.prodId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.prodId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.prodId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-md-1 text-left">
                      &nbsp; <br />
                      <button class="btn btn-primary">
                        <i class="uil uil-search-plus"></i>
                      </button>
                    </div>


                    <div class="col-md-11">
                      <div class="mb-3 position-relative">
                        <code> * </code> รถคันที่เลือก :
                        <multiselect
                          v-model="vehicleId"
                          label="nameEn"
                          type="search"
                          :options="rowsVm"
                          open-direction="bottom"
                          placeholder="ชื่อสินค้า"
                          :custom-label="customLabelVehicle"
                          :class="{
                            'is-invalid': submitform && $v.vehicleId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.vehicleId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.vehicleId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 text-left">
                      &nbsp; <br />
                      <button class="btn btn-primary">
                        <i class="uil uil-search-plus"></i>
                      </button>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> สีภายนอก :
                        <multiselect
                          v-model="exteriorColorId"
                          label="nameTh"
                          type="search"
                          :options="rowsExColor"
                          open-direction="bottom"
                          placeholder="สีภายนอก"
                          :class="{
                            'is-invalid':
                              submitform && $v.exteriorColorId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.exteriorColorId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.exteriorColorId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> สีภายใน :
                        <multiselect
                          v-model="interiorColorId"
                          label="nameTh"
                          type="search"
                          :options="rowsInColor"
                          open-direction="bottom"
                          placeholder="สีภายใน"
                          :class="{
                            'is-invalid':
                              submitform && $v.interiorColorId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.interiorColorId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.interiorColorId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <code> * </code> แคมเปญ :
                        <multiselect
                          v-model="campaignId"
                          label="nameTh"
                          type="search"
                          :options="rowsCampiagn"
                          open-direction="bottom"
                          placeholder="แคมเปญ"
                          :class="{
                            'is-invalid': submitform && $v.campaignId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.campaignId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.campaignId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 text-left">
                      &nbsp; <br />
                      <button class="btn btn-primary">
                        <i class="uil uil-search-plus"></i>
                      </button>
                    </div>
                    <hr />

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ราคาสินค้า (บาท) :
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="vehicleTotalPrice"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.vehicleTotalPrice.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.vehicleTotalPrice.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.vehicleTotalPrice.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> ส่วนลด (%) :
                        <input
                          type="number"
                          @change="calProd()"
                          style="text-align:right"
                          v-model="vehicleDcPer"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.vehicleDcPer.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.vehicleDcPer.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.vehicleDcPer.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ส่วนลด (บาท) :
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="vehicleDcPrice"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.vehicleDcPrice.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.vehicleDcPrice.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.vehicleDcPrice.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> สุทธิ (บาท) :
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="vehicleNetPrice"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.vehicleNetPrice.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.vehicleNetPrice.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.vehicleNetPrice.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> เงื่อนไขการขาย :
                        <multiselect
                          v-model="saleCondition"
                          label="scName"
                          type="search"
                          :options="rowSaleCondition"
                          open-direction="bottom"
                          placeholder="เงื่อนไขการขาย"
                          :class="{
                            'is-invalid': submitform && $v.saleCondition.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.saleCondition.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.saleCondition.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> เงินดาวน์ (บาท) :
                        <input
                          @change="calProd()"
                          type="number"
                          style="text-align:right"
                          v-model="downPayment"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.downPayment.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.downPayment.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.downPayment.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ยอดจัดไฟแนนซ์ (บาท) :
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="totalFinancing"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.totalFinancing.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.totalFinancing.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.totalFinancing.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> วันที่ชำระงวดแรก :
                        <date-picker
                          v-model="installmentDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          :class="{
                            'is-invalid':
                              submitform && $v.installmentDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.installmentDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.installmentDate.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <code> * </code> สถาบันการเงิน :
                        <multiselect
                          v-model="fiId"
                          label="nameTh"
                          type="search"
                          :options="rowsFin"
                          open-direction="bottom"
                          placeholder="สถาบันการเงิน"
                          :class="{
                            'is-invalid': submitform && $v.fiId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.fiId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.fiId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> ดอกเบี้ย/เดือน:
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="interest"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.interest.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.interest.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.interest.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> จำนวนงวด :
                        <input
                          @change="calProd()"
                          min="0"
                          type="number"
                          style="text-align:right"
                          v-model="numPeriods"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.numPeriods.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.numPeriods.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.numPeriods.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ยอดชำระต่องวด (บาท):
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="installment"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.installment.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.installment.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.installment.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        ผู้แนะนำ :
                        <multiselect
                          v-model="recommenderId"
                          label="nameTh"
                          type="search"
                          :options="rowsCustomers"
                          :show-labels="false"
                          open-direction="bottom"
                          @search-change="getRecommenderId"
                          @remove="getRecommenderId"
                          :custom-label="customLabelCustomer"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.recommenderId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.recommenderId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.recommenderId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ค่านำพา :
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="referralFee"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.referralFee.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.referralFee.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.referralFee.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> การจัดส่งทะเบียน<br />
                        <multiselect
                          v-model="licensePlateDelivery"
                          :options="postType"
                          label="nameTh"
                          :class="{
                            'is-invalid':
                              submitform && $v.licensePlateDelivery.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.licensePlateDelivery.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.licensePlateDelivery.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> การจัดส่งรถ <br />
                        <multiselect
                        v-model="productDelivery"
                          :options="postType"
                          label="nameTh"
                          :class="{
                            'is-invalid':
                              submitform && $v.productDelivery.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.productDelivery.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.productDelivery.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ประเภทภาษี <br />
                        <multiselect
                        v-model="taxType"
                          :options="taxTypeOptions"
                          label="nameTh"
                          :class="{
                            'is-invalid':
                              submitform && $v.taxType.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.taxType.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.taxType.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-8">
                      <div class="mb-3 position-relative">
                        สถานที่จัดส่ง :
                        <multiselect
                          v-model="addrId"
                          label="nameTh"
                          type="search"
                          :options="rowsArr"
                          :show-labels="false"
                          open-direction="bottom"
                          :disabled="nameTh == ''"
                          @search-change="getCustomerArr"
                          @remove="getCustomerArr"
                          :custom-label="customLabelCustomerArr"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.addrId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.addrId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.addrId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-sm-8">
                      <div class="mb-3 position-relative">
                        หมายเหตุ :
                        <textarea
                          v-model="note"
                          class="form-control"
                          name="textarea"
                          rows="5"
                          :class="{
                            'is-invalid': submitform && $v.note.$error,
                          }"
                        ></textarea>
                        <div
                          v-if="submitform && $v.note.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.note.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <b-button
                          class="btn float-end m-1"
                          variant="success"
                          @click="tooltipForm"
                        >
                          บันทึก
                        </b-button>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>

    <b-modal
          title="ค้นหาใบจ่าย"
          hide-footer
          size="xl" 
          centered
          ref="modalSelectbooking"
        >
        <div class="row">
          <div class="col-md-4">
            <input 
            class="form-control" 
            type="text" 
            v-model="searchbookingCode"
            placeholder="รหัสใบจ่าย"><br>
          </div>

          <div class="col-md-3">
            <input 
            class="form-control"
            type="text" 
            v-model="searchNameCusbooking"
            placeholder="ชื่อลูกค้า"><br>
          </div>
          <div class="col-md-2">
            <button class="btn btn-primary" @click="getdataBooking()">ค้นหา</button>
          </div>
          
        </div>
          <div class="row">
                      <div class="table-responsive mb-0">
            <b-table
              :items="rowsBooking"
              :fields="fieldsbooking"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              select-mode="single"
              @row-selected="onRowSelectedbooking"
            >
              <!-- <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template> -->

              <!-- End edit table -->
            </b-table>
          </div>
            
          </div>
        </b-modal>
  </Layout>
</template>
